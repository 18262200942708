export const Links = Object.freeze({
  DASHBOARD: { name: "Dashboard" },

  MIJN_RECRUITMENT: { name: "Recruitment" },
  RECRUITMENT_AANVRAGEN: { name: "RecruitmentAanvragen" },
  RECRUITMENT_LOSSE_VACATURES: { name: "RecruitmentLosseVacatures" },
  RECRUITMENT_NEW_LOSSE_VACATURE: { name: "RecruitmentNewLosseVacature" },
  TALENTPOOL: { name: "Talentpool" },

  MIJN_WERVING_EN_SELECTIE: { name: "WervingSelectie" },
  MIJN_TAKEN: { name: "Acties" },
  AANVRAGEN: { name: "Aanvragen" },
  NEW: { name: "NieuweAanvraag" },

  MIJN_PROFIEL: { name: "Profiel" },
  JOB_ALERT: { name: "JobAlert" },
  MIJN_TALENTEN: { name: "Profielen" },
  MIJN_SOLLICITATIES: { name: "Sollicitaties" },
  RECOMMENDATIONS: { name: "Recommendations" },

  MIJN_LOOPBAANADVIES: { name: "Loopbaanadvies" },
  LBA_PROFIELEN: { name: "Profielen" },
  LBA_VACATURES: { name: "Vacatures" },

  DATABASE: { name: "Database" },

  INTERNE_VACATURES: process.env.VUE_APP_WERKENBIJ + "vacatures",
  WERKEN_BIJ_AMSTERDAM_NL: process.env.VUE_APP_WERKENBIJ,
  CARRIERE_EN_CONTACT: process.env.VUE_APP_WERKENBIJ + "over-ons",
  FAQ: process.env.VUE_APP_WERKENBIJ + "veelgestelde-vragen",
  LOOPBAAN_OPLEIDING: process.env.VUE_APP_WERKENBIJ + "loopbaan-en-opleiding",

  LINKEDIN: "https://www.linkedin.com/company/gemeente-amsterdam/jobs/",
  FACEBOOK: "https://www.facebook.com/gemeenteamsterdam",
  TWITTER: "https://twitter.com/AmsterdamNL",
  INSTAGRAM: "https://www.instagram.com/gemeenteamsterdam/",
  YOUTUBE: "https://www.youtube.com/channel/UCEiYFPFR5jGhFakHhbswlig",

  PRIVACY:
    process.env.VUE_APP_WERKENBIJ + "algemene-privacyverklaring-medewerkers",
  COOKIES: process.env.VUE_APP_WERKENBIJ + "cookiebeleid",
  ACCESSIBILITY: process.env.VUE_APP_WERKENBIJ + "toegankelijkheid",
});
