// Taken from https://www.sitepoint.com/mime-types-complete-list/
const dmf = "x-world/x-3dmf";
const octetStream = "application/octet-stream";
const postScript = "application/postscript";
const aiff = "audio/aiff";
const plain = "text/plain";
const xDirector = "application/x-director";
const msWord = "application/msword";
const dwg = "image/vnd.dwg";
const make = "audio/make";
const hpHpgl = "application/vnd.hp-hpgl";
const jpeg = "image/jpeg";
const midi = "audio/midi";
const mpeg = "video/mpeg";
const rfc822 = "message/rfc822";
const quicktime = "video/quicktime";
const xProject = "application/x-project";
const msPowerpoint = "application/vnd.ms-powerpoint";
const xQuicktime = "image/x-quicktime";
const realAudio = "audio/x-pn-realaudio";
const xTroff = "application/x-troff";
const xKoan = "application/x-koan";
const uriList = "text/uri-list";
const xVisio = "application/x-visio";
const wordPerfect = "application/wordperfect";
const excel = "application/excel";
const msExcel = "application/vnd.ms-excel";

export const FileExtentions = Object.freeze({
  "3dm": dmf,
  "3dmf": dmf,
  a: octetStream,
  aab: "application/x-authorware-bin",
  aam: "application/x-authorware-map",
  aas: "application/x-authorware-seg",
  abc: "text/vnd.abc",
  acgi: "text/html",
  afl: "video/animaflex",
  ai: postScript,
  aif: aiff,
  aifc: aiff,
  aiff: aiff,
  aim: "application/x-aim",
  aip: "text/x-audiosoft-intra",
  ani: "application/x-navi-animation",
  aos: "application/x-nokia-9000-communicator-add-on-software",
  aps: "application/mime",
  arc: octetStream,
  arj: "application/arj",
  art: "image/x-jg",
  asf: "video/x-ms-asf",
  asm: "text/x-asm",
  asp: "text/asp",
  asx: "application/x-mplayer2",
  au: "audio/basic",
  avi: "application/x-troff-msvideo",
  avs: "video/avs-video",
  bcpio: "application/x-bcpio",
  bin: octetStream,
  bm: "image/bmp",
  bmp: "image/bmp",
  boo: "application/book",
  book: "application/book",
  boz: "application/x-bzip2",
  bsh: "application/x-bsh",
  bz: "application/x-bzip",
  bz2: "application/x-bzip2",
  c: plain,
  "c++": plain,
  cat: "application/vnd.ms-pki.seccat",
  cc: plain,
  ccad: "application/clariscad",
  cco: "application/x-cocoa",
  cdf: "application/cdf",
  cha: "application/x-chat",
  chat: "application/x-chat",
  class: "application/java",
  com: octetStream,
  conf: plain,
  cpio: "application/x-cpio",
  cpp: "text/x-c",
  cpt: "application/x-cpt",
  crl: "application/pkcs-crl",
  csh: "application/x-csh",
  css: "text/css",
  cxx: plain,
  dcr: xDirector,
  deepv: "application/x-deepv",
  def: plain,
  der: "application/x-x509-ca-cert",
  dif: "video/x-dv",
  dir: xDirector,
  dl: "video/dl",
  doc: msWord,
  dot: msWord,
  dp: "application/commonground",
  drw: "application/drafting",
  dump: octetStream,
  dv: "video/x-dv",
  dvi: "application/x-dvi",
  dwf: "model/vnd.dwf",
  dwg: dwg,
  dxf: dwg,
  dxr: xDirector,
  el: "text/x-script.elisp",
  elc: "application/x-elc",
  env: "application/x-envoy",
  eps: postScript,
  es: "application/x-esrehber",
  etx: "text/x-setext",
  evy: "application/envoy",
  exe: octetStream,
  f: plain,
  f77: "text/x-fortran",
  f90: plain,
  fdf: "application/vnd.fdf",
  fif: "image/fif",
  fli: "video/fli",
  flo: "image/florian",
  flx: "text/vnd.fmi.flexstor",
  fmf: "video/x-atomic3d-feature",
  for: plain,
  fpx: "image/vnd.fpx",
  frl: "application/freeloader",
  funk: make,
  g: plain,
  g3: "image/g3fax",
  gif: "image/gif",
  gl: "video/gl",
  gsd: "audio/x-gsm",
  gsm: "audio/x-gsm",
  gsp: "application/x-gsp",
  gss: "application/x-gss",
  gtar: "application/x-gtar",
  gz: "application/x-gzip",
  gzip: "application/x-gzip",
  h: plain,
  hdf: "application/x-hdf",
  help: "application/x-helpfile",
  hgl: hpHpgl,
  hh: plain,
  hlb: "text/x-script",
  hlp: "application/hlp",
  hpg: hpHpgl,
  hpgl: hpHpgl,
  hqx: "application/binhex",
  hta: "application/hta",
  htc: "text/x-component",
  htm: "text/html",
  html: "text/html",
  htmls: "text/html",
  htt: "text/webviewhtml",
  htx: "text/html",
  ice: "x-conference/x-cooltalk",
  ico: "image/x-icon",
  idc: plain,
  ief: "image/ief",
  iefs: "image/ief",
  iges: "model/iges",
  igs: "model/iges",
  ima: "application/x-ima",
  imap: "application/x-httpd-imap",
  inf: "application/inf",
  ins: "application/x-internett-signup",
  ip: "application/x-ip2",
  isu: "video/x-isvideo",
  it: "audio/it",
  iv: "application/x-inventor",
  ivr: "i-world/i-vrml",
  ivy: "application/x-livescreen",
  jam: "audio/x-jam",
  jav: plain,
  java: "text/x-java-source",
  jcm: "application/x-java-commerce",
  jfif: jpeg,
  "jfif-tbnl": jpeg,
  jpe: jpeg,
  jpeg: jpeg,
  jpg: jpeg,
  jps: "image/x-jps",
  js: "text/javascript",
  jut: "image/jutvision",
  kar: midi,
  ksh: "application/x-ksh",
  la: "audio/nspaudio",
  lam: "audio/x-liveaudio",
  latex: "application/x-latex",
  lha: octetStream,
  lhx: octetStream,
  list: plain,
  lma: "audio/nspaudio",
  log: plain,
  lsp: "application/x-lisp",
  lst: plain,
  lsx: "text/x-la-asf",
  ltx: "application/x-latex",
  lzh: octetStream,
  lzx: octetStream,
  m: plain,
  m1v: mpeg,
  m2a: "audio/mpeg",
  m2v: mpeg,
  m3u: "audio/x-mpequrl",
  man: "application/x-troff-man",
  map: "application/x-navimap",
  mar: plain,
  mbd: "application/mbedlet",
  mc$: "application/x-magic-cap-package-1.0",
  mcd: "application/mcad",
  mcf: "text/mcf",
  mcp: "application/netmc",
  me: "application/x-troff-me",
  mht: rfc822,
  mhtml: rfc822,
  mid: midi,
  midi: midi,
  mif: "application/x-frame",
  mime: "www/mime",
  mjf: "audio/x-vnd.audioexplosion.mjuicemediafile",
  mjpg: "video/x-motion-jpeg",
  mm: "application/base64",
  mme: "application/base64",
  mod: "audio/mod",
  moov: quicktime,
  mov: quicktime,
  movie: "video/x-sgi-movie",
  mp2: mpeg,
  mp3: mpeg,
  mpa: mpeg,
  mpc: xProject,
  mpe: mpeg,
  mpeg: mpeg,
  mpg: mpeg,
  mpga: "audio/mpeg",
  mpp: "application/vnd.ms-project",
  mpt: xProject,
  mpv: xProject,
  mpx: xProject,
  mrc: "application/marc",
  ms: "application/x-troff-ms",
  mv: "video/x-sgi-movie",
  my: make,
  mzz: "application/x-vnd.audioexplosion.mzz",
  nap: "image/naplps",
  naplps: "image/naplps",
  nc: "application/x-netcdf",
  ncm: "application/vnd.nokia.configuration-message",
  nif: "image/x-niff",
  niff: "image/x-niff",
  nix: "application/x-mix-transfer",
  nsc: "application/x-conference",
  nvd: "application/x-navidoc",
  o: octetStream,
  oda: "application/oda",
  omc: "application/x-omc",
  omcd: "application/x-omcdatamaker",
  omcr: "application/x-omcregerator",
  p: "text/x-pascal",
  p10: "application/pkcs10",
  p12: "application/pkcs-12",
  p7a: "application/x-pkcs7-signature",
  p7c: "application/pkcs7-mime",
  p7m: "application/pkcs7-mime",
  p7r: "application/x-pkcs7-certreqresp",
  p7s: "application/pkcs7-signature",
  part: "application/pro_eng",
  pas: "text/pascal",
  pbm: "image/x-portable-bitmap",
  pcl: "application/vnd.hp-pcl",
  pct: "image/x-pict",
  pcx: "image/x-pcx",
  pdb: "chemical/x-pdb",
  pdf: "application/pdf",
  pfunk: make,
  pgm: "image/x-portable-graymap",
  pic: "image/pict",
  pict: "image/pict",
  pkg: "application/x-newton-compatible-pkg",
  pko: "application/vnd.ms-pki.pko",
  pl: plain,
  plx: "application/x-pixclscript",
  pm: "image/x-xpixmap",
  pm4: "application/x-pagemaker",
  pm5: "application/x-pagemaker",
  png: "image/png",
  pnm: "image/x-portable-anymap",
  pot: msPowerpoint,
  pov: "model/x-pov",
  ppa: msPowerpoint,
  ppm: "image/x-portable-pixmap",
  pps: msPowerpoint,
  ppt: msPowerpoint,
  ppz: "application/mspowerpoint",
  pre: "application/x-freelance",
  prt: "application/pro_eng",
  ps: postScript,
  psd: octetStream,
  pvu: "paleovu/x-pv",
  pwz: msPowerpoint,
  py: "text/x-script.phyton",
  pyc: "application/x-bytecode.python",
  qcp: "audio/vnd.qcelp",
  qd3: dmf,
  qd3d: dmf,
  qif: xQuicktime,
  qt: quicktime,
  qtc: "video/x-qtc",
  qti: xQuicktime,
  qtif: xQuicktime,
  ra: "audio/x-realaudio",
  ram: realAudio,
  ras: "image/cmu-raster",
  rast: "image/cmu-raster",
  rexx: "text/x-script.rexx",
  rf: "image/vnd.rn-realflash",
  rgb: "image/x-rgb",
  rm: "application/vnd.rn-realmedia",
  rmi: "audio/mid",
  rmm: realAudio,
  rmp: realAudio,
  rnx: "application/vnd.rn-realplayer",
  roff: xTroff,
  rp: "image/vnd.rn-realpix",
  rpm: "audio/x-pn-realaudio-plugin",
  rt: "text/richtext",
  rtf: "application/rtf",
  rtx: "application/rtf",
  rv: "video/vnd.rn-realvideo",
  s: "text/x-asm",
  s3m: "audio/s3m",
  saveme: octetStream,
  sbk: "application/x-tbook",
  scm: "video/x-scm",
  sdml: plain,
  sdp: "application/sdp",
  sdr: "application/sounder",
  sea: "application/sea",
  set: "application/set",
  sgm: "text/sgml",
  sgml: "text/sgml",
  sh: "text/x-script.sh",
  shar: "application/x-shar",
  shtml: "text/html",
  sid: "audio/x-psid",
  sit: "application/x-sit",
  skd: xKoan,
  skm: xKoan,
  skp: xKoan,
  skt: xKoan,
  sl: "application/x-seelogo",
  smi: "application/smil",
  smil: "application/smil",
  snd: "audio/basic",
  sol: "application/solids",
  spc: "text/x-speech",
  spl: "application/futuresplash",
  spr: "application/x-sprite",
  sprite: "application/x-sprite",
  src: "application/x-wais-source",
  ssi: "text/x-server-parsed-html",
  ssm: "application/streamingmedia",
  sst: "application/vnd.ms-pki.certstore",
  step: "application/step",
  stl: "application/sla",
  stp: "application/step",
  sv4cpio: "application/x-sv4cpio",
  sv4crc: "application/x-sv4crc",
  svf: dwg,
  swf: "application/x-shockwave-flash",
  t: xTroff,
  talk: "text/x-speech",
  tar: "application/x-tar",
  tbk: "application/toolbook",
  tcl: "application/x-tcl",
  tcsh: "text/x-script.tcsh",
  tex: "application/x-tex",
  texi: "application/x-texinfo",
  texinfo: "application/x-texinfo",
  text: plain,
  tgz: "application/x-compressed",
  tif: "image/tiff",
  tiff: "image/tiff",
  tr: xTroff,
  tsi: "audio/tsp-audio",
  tsp: "application/dsptype",
  tsv: "text/tab-separated-values",
  turbot: "image/florian",
  txt: plain,
  uil: "text/x-uil",
  uni: uriList,
  unis: uriList,
  unv: "application/i-deas",
  uri: uriList,
  uris: uriList,
  ustar: "application/x-ustar",
  uu: octetStream,
  uue: "text/x-uuencode",
  vcd: "application/x-cdlink",
  vcs: "text/x-vcalendar",
  vda: "application/vda",
  vdo: "video/vdo",
  vew: "application/groupwise",
  viv: "video/vnd.vivo",
  vivo: "video/vnd.vivo",
  vmd: "application/vocaltec-media-desc",
  vmf: "application/vocaltec-media-file",
  voc: "audio/voc",
  vos: "video/vosaic",
  vox: "audio/voxware",
  vqe: "audio/x-twinvq-plugin",
  vqf: "audio/x-twinvq",
  vql: "audio/x-twinvq-plugin",
  vrml: "application/x-vrml",
  vrt: "x-world/x-vrt",
  vsd: xVisio,
  vst: xVisio,
  vsw: xVisio,
  w60: "application/wordperfect6.0",
  w61: "application/wordperfect6.1",
  w6w: msWord,
  wav: "audio/wav",
  wb1: "application/x-qpro",
  wbmp: "image/vnd.wap.wbmp",
  web: "application/vnd.xara",
  wiz: msWord,
  wk1: "application/x-123",
  wmf: "windows/metafile",
  wml: "text/vnd.wap.wml",
  wmlc: "application/vnd.wap.wmlc",
  wmls: "text/vnd.wap.wmlscript",
  wmlsc: "application/vnd.wap.wmlscriptc",
  word: msWord,
  wp: wordPerfect,
  wp5: wordPerfect,
  wp6: wordPerfect,
  wpd: wordPerfect,
  wq1: "application/x-lotus",
  wri: "application/mswrite",
  wrl: "model/vrml",
  wrz: "model/vrml",
  wsc: "text/scriplet",
  wsrc: "application/x-wais-source",
  wtk: "application/x-wintalk",
  xbm: "image/xbm",
  xdr: "video/x-amt-demorun",
  xgz: "xgl/drawing",
  xif: "image/vnd.xiff",
  xl: excel,
  xla: excel,
  xlb: msExcel,
  xlc: msExcel,
  xld: "application/x-excel",
  xlk: "application/x-excel",
  xll: msExcel,
  xlm: msExcel,
  xls: msExcel,
  xlt: excel,
  xlv: excel,
  xlw: msExcel,
  xm: "audio/xm",
  xml: "application/xml",
  xmz: "xgl/movie",
  xpix: "application/x-vnd.ls-xpix",
  xpm: "image/xpm",
  "x-png": "image/png",
  xsr: "video/x-amt-showrun",
  xwd: "image/x-xwd",
  xyz: "chemical/x-pdb",
  z: "application/x-compressed",
  zip: "application/zip",
  zoo: octetStream,
  zsh: "text/x-script.zsh",

  //Content types below this point are retrieved from different sources
  dgn: "image/vnd.dgn",
  eml: rfc822,
  msg: "application/vnd.ms-outlook",
  mkv: "video/x-matroska",
  m4p: "audio/m4p",
});

export const Files = Object.freeze({
  maxSize: 100000000,
});

export const Identifications = Object.freeze({
  AANVRAAG_CAPACITEIT: "4cacef5f-da68-4730-81d0-d76ac928e8b4",
  AFAS_SYNCHRONISATIE: "6ff52979-7d78-4e09-ad0f-571497a897e1",
  BACKGROUND: "c254cf89-3d1a-4738-802a-80c6c8c5dd1f",
  BACKOFFICE: "c0697f32-fbe4-495d-9eb6-186e0790fc96",
  DIRECTEUR: "fc84405f-8e04-4489-b5d4-9884c6d7ca83",
  FUNCTIENAAM: "dfc723ce-cc4c-4149-b306-8b0308fee2fc",
  INCIDENT: "d9f3c45e-4868-479f-99cf-f6e3e863c91b",
  KANDIDAAT: "3ea703ea-22ba-4398-824f-d16b24f7549f",
  LEIDINGGEVENDE: "eb9bfd6e-f861-417d-8dd3-bba5c3ff0fe0",
  NOTE: "c5a0b5cc-f9dd-412f-b0d0-aa4dea84da79",
  ORGANISATION: "95b52b57-7ea5-4b8e-a986-1e1d79becdaf",
  PDFTemplates: "3179d501-de5d-448f-af79-60eaaad9ff15",
  SELECTERENENBESLISSEN: "c4245e98-b90c-4e97-836b-d878a75219d2",
  TALENT_PROFIEL: "4d6cd2c3-933b-4f5a-9bd0-9156c98cf0aa",
  USER: "e5453dd2-7cd0-4de6-b38a-89904b8f86bd",
  VOORRANGSKANDIDAAT: "7fb23540-a724-41bc-bea2-816a74e21734",
  WERKLOCATIE: "dd45fb7b-001b-4777-a5d8-85758db0ffc9",
  WERKTITEL: "0f6fe8b8-987e-49f0-a6ad-05fae81d8232",
  LOSSE_VACATURE: "7e429fd2-533c-41a6-a993-78c7a8201b3d",
  TALENTPOOL_PROFIEL: "12145c6f-e0ce-46ff-96c5-01e334757e55",
  TALENTPOOL: "1dcdf36e-e42b-4bb3-800e-b2715e45b1e5",

  STAGE_DEFINITION_SELECTEREN: "37d476c5-df05-4e2f-b566-d746519f3f9e",
  STAGE_DEFINITION_VOLTOOIEN_AANVRAAG: "d30c3741-ae9a-4c1d-85f4-f23bc9564c4c",
  STAGE_DEFINITION_INTREKKEN_SOLLICITATIE:
    "74e2c506-95ab-4b4d-a5b9-25b695493034",
  STAGE_DEFINITION_AFAS_KOPPELING: "4bc4533e-7e91-4f5a-b037-3c479ea829cb",
  STAGE_DEFINITION_JOBALERT_BIJWERKEN: "605d19a0-9075-4a38-8cca-5e52eb891cf4",
  STAGE_DEFINITION_JOBALERT_AFMELDEN: "99556200-b869-4487-9556-1d4db5b69f3a",

  PDF_GENERATOR: "1136032a-9262-4753-91f1-b8e31971917c",

  CONCEPT_AANVRAAG_CAPACITEIT: "7a5c6915-4522-4733-94fd-d7e819deace5",
  CONCEPT_LOSSE_VACATURE: "411d128b-aeda-4cba-a3be-790074fb7619",
});

export const Roles = Object.freeze({
  SELECTIECOMMISSIE: "Selectiecommissie",
  MANAGER: "Manager",
  AANVRAGER: "Aanvrager",
  PRIMAIR_CONTACTPERSOON: "Primair contactpersoon",
  SECUNDAIR_CONTACTPERSOON_1: "Secundair contactpersoon 1",
  SECUNDAIR_CONTACTPERSOON_2: "Secundair contactpersoon 2",
  TALENT_PROFIEL_HOUDER: "Talentprofielhouder",
  VOORRANGSKANDIDAAT_HOUDER: "Voorrangskandidaathouder",
  VOORRANGSKANDIDAAT: "Voorrangskandidaat",
  KANDIDAAT_HOUDER: "Kandidaathouder",
  LOOPBAAN_ADVISEUR: "Loopbaan adviseur",
  TALENTPOOL_HOUDER: "Talentpool houder",
  EIGENAAR_PROFIEL: "Eigenaar profiel",
  TALENTPOOL_LID: "Talentpool lid",
  INTERNE_KANDIDAAT: "Interne Kandidaat",
  EXTERNE_KANDIDAAT: "Externe Kandidaat",
});

export const Codes = Object.freeze({
  AANVRAAG_CAPACITEIT: "AANVRAAG_CAPACITEIT",
  AFAS_SYNCHRONISATIE: "AFAS_SYNCHRONISATIE",
  BACKGROUND_PROCESS: "BACKGROUND",
  BACKOFFICE: "BACKOFFICE",
  DIRECTEUR: "DIRECTEUR",
  FUNCTIENAAM: "FUNCTIENAAM",
  INCIDENT: "INCIDENT",
  KANDIDAAT: "KANDIDAAT",
  LEIDINGGEVENDE: "LEIDINGGEVENDE",
  NOTE: "NOTE",
  ORGANISATION: "ORGANISATION",
  PDFTemplates: "PDFTemplates",
  SELECTERENENBESLISSEN: "SELECTERENENBESLISSEN",
  TALENT_PROFIEL: "TALENT_PROFIEL",
  USER: "USER",
  VOORRANGSKANDIDAAT: "VOORRANGSKANDIDAAT",
  WERKLOCATIE: "WERKLOCATIE",
  WERKTITEL: "WERKTITEL",
  LOSSE_VACATURE: "LOSSE_VACATURE",
});

export const ConceptCodes = Object.freeze({
  AANVRAAG_CAPACITEIT: "NA",
  LOSSE_VACATURE: "LV",
});

// Type of actions a user can perform (important for checking permissions)
export const ActionType = Object.freeze({
  GET: "get",
  POST: "post",
  PUT: "put",
  PATCH: "patch",
  DELETE: "delete",
  COPY: "copy",
  HEAD: "head",
  OPTIONS: "options",
  LINK: "link",
  UNLINK: "unlink",
  PURGE: "purge",
  LOCK: "lock",
  UNLOCK: "unlock",
  PROPFIND: "propfind",
  VIEW: "view",
});

export const RolesAllowedToReview = Object.freeze({
  ADMINISTRATOR: "d754ed82-fb46-4dca-a6fc-3e1b7665361e",
  BACKOFFICE: "8861bf51-eafe-443d-bb46-ca41242364b2",
  RECRUITER: "8ca64ae6-bcb1-40b0-9f5d-78750af175b0",
  LOOPBAAN_ADVISEUR: "9f3f1eb2-694d-4a0f-bfed-409c32643b33",
});

export const ContextAllowedToReview = Object.freeze({
  MANAGER: "Manager",
  SELECTIECOMMISSIE: "Selectiecommissie",
  PRIMAIRE_CONTACTPERSOON: "Primair contactpersoon",
});

export const SpecialSteps = Object.freeze({
  SELECTIERONDE: "d7b447eb-5a43-4848-b517-bbe4f5e3e2cb",
  ANONIMISEREN: "8090a369-5f16-4d75-afe2-84eff04b8f62",
});

export const Statuses = Object.freeze({
  AANVULLING_NODIG: {
    value: "AANVULLING NODIG",
    label: "Aanvulling nodig",
  },
  ACCORDEREN_WERVINGSTEKST: {
    value: "ACCORDEREN WERVINGSTEKST",
    label: "Accorderen wervingstekst",
  },
  ALL: {
    value: "",
    label: "Alle statussen",
  },
  CONCEPT: {
    value: "CONCEPT",
    label: "Concept",
  },
  DEFINITIEF_INGEVULD: {
    value: "DEFINITIEF INGEVULD",
    label: "Definitief ingevuld",
  },
  GEANNULEERD: {
    value: "GEANNULEERD",
    label: "Geannuleerd",
  },
  GEPUBLICEERD: {
    value: "GEPUBLICEERD",
    label: "Gepubliceerd",
  },
  IN_BEHANDELING: {
    value: "IN BEHANDELING",
    label: "In behandeling",
  },
  INGETROKKEN: {
    value: "INGETROKKEN",
    label: "Ingetrokken",
  },
  MOBILITEITSFASE: {
    value: "MOBILITEITSFASE",
    label: "Mobiliteitsfase",
  },
  NIEUW: {
    value: "NIEUW",
    label: "Nieuw",
  },
  TER_AKKOORD: {
    value: "TER AKKOORD",
    label: "Ter akkoord",
  },
  TER_PUBLICATIE: {
    value: "TER PUBLICATIE",
    label: "Ter publicatie",
  },
  VERLENGING_EXTERNE_PARTIJ: {
    value: "VERLENGING EXTERNE PARTIJ",
    label: "Verlenging externe partij",
  },
  VOORLOPIG_INGEVULD: {
    value: "VOORLOPIG INGEVULD",
    label: "Voorlopig ingevuld",
  },
});

export const dateFormatOptions = Object.freeze({
  year: "numeric",
  month: "numeric",
  day: "numeric",
});

export const searchFields = Object.freeze(["NAME", "CONTENT"]);

export const Recruitment = Object.freeze({
  ACTIVITIES_WITH_PREVIEW: [
    "9ffe0495-83ee-40db-875c-be3b8fbd4c5a",
    "7abc5082-80b9-4e56-bdb6-47a9387396ac",
    "35d6bdb4-29b2-4bc2-b8db-937ba20e3291",
    "a16fc1b4-4989-4cfc-9285-3564803c7796",
  ],
  ACTIVITIES_WITH_TEMP_SAVE: ["e4bf5a26-43e5-4b17-af11-a59a5e7c664c"],
});

// Actions using Wizard component to navigate user to next section.
// The user should land on teh top of the new wizard page.
export const ActionsWithWizardTopNavigation = Object.freeze({
  OPSTELLEN_WERVINGSTEKST: "9ffe0495-83ee-40db-875c-be3b8fbd4c5a",
});

export const DeeplinkActions = Object.freeze({
  CREATE_CAPACITY_REQUEST: "nieuweAanvraag",
  WERVING_SELECTIE: "wervingselectie",
  WERVING_SELECTIE_ACTIES: "wervingselectieActies",
  WERVING_SELECTIE_AANVRAGEN: "wervingselectieAanvragen",

  RECRUITMENT: "recruitment",
  RECRUITMENT_AANVRAGEN: "recruitmentAanvragen",
  RECRUITMENT_LOSSE_VACATURES: "recruitmentLosseVacatures",
  RECRUITMENT_NEW_LOSSE_VACATURE: "recruitmentNewLosseVacature",
  RECRUITMENT_TALENTPOOL: "recruitmentTalentpool",

  MY_PROFILE: "mijnProfiel",
  MY_APPLICATIONS: "mijnSollicitaties",
  MY_JOBALERTS: "mijnJobAlerts",

  LOOPBAANADVIES: "loopbaanadvies",
  LOOPBAANADVIES_PROFIELEN: "loopbaanadviesProfielen",
  LOOPBAANADVIES_VACATURES: "loopbaanadviesVacatures",

  DATABASE: "database",
});

export const BulkActions = Object.freeze({
  PRESELECTIE_IN_RESERVE: "33b6022f-6a42-414a-b520-e0d386d349e4", // In reserve plaatsen
  PRESELECTIE_AFWIJZEN: "4dab9b95-3a43-4fc8-b3ef-eceb0aac2553", // Afwijzen
  PRESELECTIE_VRIJE_MAIL: "e5bfed79-fdeb-4d5a-a9a4-9880b09a41eb", // Versturen vrije e-mail
  PRESELECTIE_AFRONDEN: "30caaaf1-25ae-48ce-94e0-8bcedd16e6e9", // Afronden preselectie

  SELECTIE_IN_RESERVE: "dd56b9c1-f7ec-4fd2-b514-2a18d5218882", // In reserve plaatsen
  SELECTIE_AFWIJZEN: "470e8070-1c4b-4750-9c78-05024e9bcc19", // Afwijzen
  SELECTIE_VRIJE_MAIL: "4cdbe58b-a768-4a7a-bb9c-cfac59c16050", // Versturen vrije e-mail
});

export const ConfigurationCodes = Object.freeze({
  EXCEL_EXPORT: "EXCEL_EXPORT",
});

export const DynamicVariables = Object.freeze({
  CURRENT_USER: "$CURRENT_USER",
  CURRENT_ORGANISATION: "$CURRENT_ORGANISATION",
  CURRENT_USER_NAME: "$CURRENT_USER_NAME",
  CURRENT_USER_ID: "$CURRENT_USER_ID",
});

export const NotificationsType = Object.freeze({
  TALENT: "talentprofiel",
  AANVRAAG: "capaciteitsaanvraag",
});

export const ColumnTypes = Object.freeze({
  STRING: "STRING",
  BOOLEAN: "BOOLEAN",
  TIMESTAMP: "TIMESTAMP",
  DATE: "DATE",
  DATE_TIME: "DATE-TIME",
  PROGRESS: "PROGRESS",
  BUTTON: "BUTTON",
  TAGS: "TAGS",
});

export const StandardFilters = Object.freeze({
  WSM_FILTER: "WSM_FILTER",
});

export const SortDirections = Object.freeze({
  ASCENDING: "ascending",
  DESCENDING: "descending",
});

export const BannerText = Object.freeze({
  ZOMERRECES:
    "Van 25 juli t/m 26 augustus is het Vacatureloket alleen van 9.00 uur tot 13.00 uur telefonisch bereikbaar. In verband met de zomervakantie is er een lagere bezetting en kan het 2 à 3 werkdagen langer duren voordat jouw aanvraag of verlenging in behandeling wordt genomen.",
  VACATURE_PROCESS: {
    CONTENT:
      "Voor vaste en tijdelijke vacatures is het proces gewijzigd. Wij organiseren informatiesessies over deze aanpassingen. ",
    LINK: "Klik hier om je aan te melden.",
    URL: "https://tamtam.amsterdam.nl/do/startpage?id=3911743-737461727470616765",
  },
});

export const PageSize = Object.freeze({
  RecruitmentText: 5,
});

export const PageNumber = Object.freeze({
  RecruitmentText: 1,
  OmniAdvancedTable: 1,
});

export const Sorting = Object.freeze({
  DESCENDING: {
    sort_type: "text_score",
    direction: "DESCENDING",
  },
  ASCENDING: {
    sort_type: "text_score",
    direction: "ASCENDING",
  },
});

export const RoutesTitles = Object.freeze({
  ORGANISATIES: "Organisaties",
  DASHBOARD: "Dashboard",
  SOLLICITATIE_ACTIE: "Sollicitatie actie",
  SOLLICITATIE_DETAILS: "Sollicitatie details",
  MIJN_SOLLICITATIES: "Mijn sollicitaties",
  ACTIE: "Actie",
  KANDIDAAT: "Kandidaat",
  BULKACTIE: "Bulkactie",
  AANVRAAG: "Aanvraag",
  MIJN_AANVRAGEN: "Mijn aanvragen",
  MIJN_ACTIES: "Mijn acties",
  NIEUWE_AANVRAAG: "Nieuwe Aanvraag",
  MIJN_WS: "Mijn Werving & Selectie",
  PROFIEL_ACTIE: "Profiel Actie",
  JOB_ALERTS: "Job alerts",
  MIJN_PROFIEL: "Mijn Profiel",
  AANBEVOLEN_VACATURES: "Aanbevolen vacatures",
  RECRUITMENT_KANDIDAAT_ACTIE: "Recruitment Kandidaat Actie",
  RECRUITMENT_KANDIDAAT: "Recruitment Kandidaat",
  RECRUITMENT_AANVRAAG_ACTIE: "Recruitment Aanvraag Actie",
  RECRUITMENT_BULKACTIE: "Recruitment Bulkactie",
  RECRUITMENT_AANVRAAG: "Recruitment Aanvraag",
  RECRUITMENT_AANVRAGEN: "Recruitment Aanvragen",
  NIEUWE_LOSSE_VACATURE: "Nieuwe losse vacature",
  LOSSE_VACATURE_ACTIE: "Losse vacature actie",
  LOSSE_VACATURE: "Losse vacature",
  LOSSE_VACATURES: "Losse vacatures",
  MIJN_RECRUITMENT: "Mijn recruitment",
  LBA_KANDIDAAT_ACTIE: "Loopbaanadvies Kandidaat Actie",
  LBA_KANDIDAAT: "Loopbaanadvies Kandidaat",
  LBA_ACTIE: "Loopbaanadvies Actie",
  LBA_BULKACTIE: "Loopbaanadvies Bulkactie",
  LBA_VACATURE: "Loopbaanadvies Vacature",
  LBA_VACATURES: "Loopbaanadvies Vacatures",
  PROFIEL_ACTIE_LBA: "Profiel Actie voor LBA",
  LBA_PROFIEL: "Loopbaanadvies Profiel",
  LBA_PROFIELEN: "Loopbaanadvies Profielen",
  MIJN_LBA: "Mijn Loopbaanadvies",
  DATABASE: "Database",
  TALENTPOOL: "Talentpool",
  TALENTPOOL_PROFIEL: "Talentpool profiel",
  TALENTPOOL_PROFIEL_ACTIE: "Talentpool profiel actie",
});

export const RoutesNames = Object.freeze({
  NIEUWE_AANVRAAG: "NieuweAanvraag",
  WERVINGSELECTIE: "WervingSelectie",
  ACTIES: "Acties",
  AANVRAAG: "Aanvraag",
  AANVRAGEN: "Aanvragen",
  KANDIDAAT: "Kandidaat",

  RECRUITMENT: "Recruitment",
  RECRUITMENT_AANVRAGEN: "RecruitmentAanvragen",
  RECRUITMENT_LOSSE_VACATURES: "LosseVacatures",
  RECRUITMENT_NEW_LOSSE_VACATURE: "NieuweLosseVacature",
  RECRUITMENT_TALENTPOOL: "Talentpool",

  PROFIEL: "Profiel",
  TALENTPOOL_PROFIEL: "TalentpoolProfiel",
  SOLLICITATIES: "Sollicitaties",
  SOLLICITATIE_DETAILS: "SollicitatieDetails",
  JOB_ALERT: "JobAlert",

  LOOPBAANADVIES: "Loopbaanadvies",
  PROFIELEN: "Profielen",
  LBA_PROFIEL: "LBAProfiel",
  VACATURES: "Vacatures",

  DATABASE: "Database",
});

export const Breadcrumbs = Object.freeze({
  DYNAMIC: "dynamic",
  DASHBOARD: "Dashboard",
  MIJN_SOLLICITATIES: "Mijn sollicitaties",
  AANVRAGEN: "Aanvragen",
  MIJN_ACTIES: "Mijn acties",
  NIEUWE_AANVRAAG: "Nieuwe Aanvraag",
  MIJN_WS: "Mijn Werving & Selectie",
  JOB_ALERTS: "Job alerts",
  MIJN_PROFIEL: "Mijn Profiel",
  AANBEVOLEN_VACATURES: "Aanbevolen vacatures",
  RECRUITMENT_AANVRAAG: "Recruitment Aanvraag",
  RECRUITMENT_AANVRAGEN: "Recruitment Aanvragen",
  NIEUWE_LOSSE_VACATURE: "Nieuwe losse vacature",
  LOSSE_VACATURES: "Losse vacatures",
  MIJN_RECRUITMENT: "Mijn recruitment",
  VACATURES: "Vacatures",
  PROFIELEN: "Profielen",
  MIJN_LBA: "Mijn Loopbaanadvies",
  DATABASE: "Database",
  TALENTPOOL: "Talentpool",
  TALENTPOOL_PROFIEL: "Talentpool profiel",
});

export const DataType = Object.freeze({
  DATE_TIME: "DATE-TIME",
  STRING: "STRING",
});

/**
 * Names of the routes that allow preset management in OmniAdvancedTable
 * @type {Readonly<string[]>}
 */
export const OmniAdvancedTablePresets = Object.freeze([
  "RecruitmentAanvragen",
  "Vacatures",
  "Talentpool",
]);

export const KeysGroup = Object.freeze({
  GENERAL: "General",
  ALGEMEEN: "Algemeen",
  PROPERTIES: "Properties",
  CONTENT: "Content",
});

export const MediaLinkTypes = Object.freeze({
  CONCEPT: "CONCEPT",
  INSTANCE: "INSTANCE",
  TENANT: "TENANT",
  NONE: "NONE",
});

export const MediaDefinitions = Object.freeze({
  AANVRAAG_BIJLAGE: "b4b5e90c-3876-4eeb-a6ed-0beee14bc41d",
  C_V: "7c8b61f2-2832-4193-9a21-7767b9cbf7d7",
  MOTIVATIE: "46341f23-9dc3-40c7-9cc2-7ed1a76ea7ee",
});

export const ActivityStates = Object.freeze({
  CREATED: "CREATED",
  STARTED: "STARTED",
  FINISHED: "FINISHED",
  EXPECTED: "EXPECTED",
});
